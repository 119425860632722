import { useState, useEffect, createContext } from 'react'
import axios from 'axios'
import Chart from '../components/Chart'
const seedData = require('../data/seed_v2.json')

export const ChartDataContext = createContext()

const CardSection = () => {
  const [chartData, setChartData] = useState({})
  let appKey = '7e89269b619fe402fc90b5167263feae'

  const t = window.TrelloPowerUp.iframe({
    appKey: appKey,
    appName: 'Hill Charts',
  })

  const updateChartData = (newChartData) => {
    newChartData.updated_at = Date.now()
    setChartData(newChartData)
    t.set('card', 'shared', 'chartData', newChartData)
  }

  useEffect(async () => {
    let data = await getData()
    console.log(data)
    updateChartData(data)
  }, [])

  return (
    <div>
      <ChartDataContext.Provider value={{ chartData, updateChartData }}>
        <Chart />
      </ChartDataContext.Provider>
    </div>
  )
}

const getData = async () => {
  let appKey = '7e89269b619fe402fc90b5167263feae'

  const t = window.TrelloPowerUp.iframe({
    appKey: appKey,
    appName: 'Hill Charts',
  })

  let storedChartData = (await t.get('card', 'shared', 'chartData')) || {}
  let token = await t.getRestApi().getToken()
  let cardData = await t.card('all')
  let checklistResponse = await axios.get(
    `https://api.trello.com/1/cards/${cardData.id}/checklists?key=${appKey}&token=${token}`
  )

  let storedPoints = storedChartData.points || []
  let updatedPoints = []

  checklistResponse.data.forEach((checklist) => {
    let existingPoint = storedPoints.find((point) => point.id == checklist.id)
    if (existingPoint) {
      existingPoint.label = checklist.name
      updatedPoints.push(existingPoint)
    } else {
      let newPoint = {
        id: checklist.id,
        updated_at: Date.now(),
        label: checklist.name,
        x: 0,
        y: 125,
      }
      updatedPoints.push(newPoint)
    }
  })

  storedChartData.updated_at = Date.now()
  storedChartData.points = colorizer(updatedPoints)

  t.set('card', 'shared', 'chartData', storedChartData)

  return storedChartData
}

const colorizer = (pointData) => {
  let colors = ['red', 'yellow', 'green', 'blue', 'purple', 'pink']

  pointData.forEach((point) => {
    if (colors.includes(point.color)) {
      let colorIndex = colors.findIndex((color) => color == point.color)
      colors.splice(colorIndex, 1)
      colors.push(point.color)
    }
  })

  pointData
    .filter((point) => !point.color)
    .forEach((point) => {
      point.color = colors[0]
      point.shade = 400
      point.updated_at = Date.now()
      colors.push(colors.shift())
    })

  return pointData
}

export default CardSection
