import { useEffect, useContext } from 'react'
import { ChartDataContext } from '../pages/CardSection'

const Legend = () => {
  const { chartData } = useContext(ChartDataContext)
  let appKey = '7e89269b619fe402fc90b5167263feae'

  const t = window.TrelloPowerUp.iframe({
    appKey: appKey,
    appName: 'Hill Charts',
  })

  useEffect(() => {
    t.sizeTo('.shell')
  }, [chartData])

  return (
    <div className="flex flex-wrap justify-center pt-2 space-x-4">
      {chartData.points?.map((pointData) => (
        <div
          className="flex items-center flex-shrink-0 py-1 space-x-1 text-xs"
          key={pointData.id}
        >
          <div
            className={`w-4 h-4 rounded-full bg-${pointData.color}-400`}
          ></div>
          <p className="">{pointData.label}</p>
        </div>
      ))}
    </div>
  )
}

export default Legend
