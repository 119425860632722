const AuthPage = () => {
  console.log('auth page')
  const t = window.TrelloPowerUp.iframe({
    appKey: '7e89269b619fe402fc90b5167263feae',
    appName: 'Hill Charts',
  })

  const handleClick = () => {
    t.getRestApi()
      .authorize({ scope: 'read,write,account' })
      .then(() => {
        console.log('auth worked!')
        t.closePopup()
      })
      .catch((err) => console.error(err))
  }

  return <button onClick={handleClick}>Click to authorize</button>
}

export default AuthPage
