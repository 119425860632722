import { useState, useEffect, useContext } from 'react'
import { ChartDataContext } from '../pages/CardSection'
import { gsap } from 'gsap'
import { Draggable } from 'gsap/Draggable.js'
import { closestPoint } from '../utils/chart.js'

gsap.registerPlugin(Draggable)

const Point = ({ data, path }) => {
  const { chartData, updateChartData } = useContext(ChartDataContext)
  const [localData, setLocalData] = useState(data)
  const pathLength = path.getTotalLength() || 0
  const startPoint = path.getPointAtLength(0) || { x: 0, y: 125 }
  let handle

  const pointModifier = (point) => {
    let p = closestPoint(path, pathLength, point)
    // console.log(p.point.x, p.point.y, Date.now())

    localData.x = p.point.x
    localData.y = p.point.y
    localData.updated_at = Date.now()

    return p.point
  }

  const updateCoordinates = () => {
    let storedData = { ...chartData }
    setLocalData(localData)
    updateChartData(storedData)
  }

  useEffect(() => {
    handle = document.querySelector(`#handle-${localData.id}`)
    gsap.set(handle, {
      transformOrigin: 'center',
      xPercent: -50,
      yPercent: -50,
      x: localData.x || startPoint.x,
      y: localData.y || startPoint.y,
    })
    new Draggable(handle, {
      liveSnap: {
        points: pointModifier,
      },
      onDragEnd: updateCoordinates,
    })
    gsap.set('.chart-bg', {
      autoAlpha: 1,
    })
  }, [])

  return (
    <g id={`handle-${localData.id}`}>
      <circle
        className={`fill-current text-${localData.color}-400`}
        cx="10"
        cy="10"
        r="10"
      />
    </g>
  )
}

export default Point
