import { useEffect } from 'react'
import { getCapabilities } from '../utils/trello'

const Home = () => {
  useEffect(() => {
    getCapabilities()
    console.log(process.env.APP_KEY)
  }, [])

  return <p className="">yo</p>
}

export default Home
