// Bell curve
export const bellCurve = (x1, x2, height) => {
  var width = x2 - x1
  var quart = width / 4

  return `M0 ${height} C ${quart} ${height}, ${quart} 0, ${quart * 2} 0, ${
    quart * 3
  } 0, ${quart * 3} ${height}, ${quart * 4} ${height}`
}

// Closest Point!!!
export const closestPoint = (pathNode, pathLength, point) => {
  var precision = 8,
    best,
    bestLength,
    bestDistance = Infinity

  // linear scan for coarse approximation
  for (
    var scan, scanLength = 0, scanDistance;
    scanLength <= pathLength;
    scanLength += precision
  ) {
    if (
      (scanDistance = distance2(
        (scan = pathNode.getPointAtLength(scanLength))
      )) < bestDistance
    ) {
      ;(best = scan), (bestLength = scanLength), (bestDistance = scanDistance)
    }
  }

  // binary search for precise estimate
  precision /= 2
  while (precision > 0.5) {
    var before, after, beforeLength, afterLength, beforeDistance, afterDistance
    if (
      (beforeLength = bestLength - precision) >= 0 &&
      (beforeDistance = distance2(
        (before = pathNode.getPointAtLength(beforeLength))
      )) < bestDistance
    ) {
      ;(best = before),
        (bestLength = beforeLength),
        (bestDistance = beforeDistance)
    } else if (
      (afterLength = bestLength + precision) <= pathLength &&
      (afterDistance = distance2(
        (after = pathNode.getPointAtLength(afterLength))
      )) < bestDistance
    ) {
      ;(best = after),
        (bestLength = afterLength),
        (bestDistance = afterDistance)
    } else {
      precision /= 2
    }
  }

  return {
    point: best,
  }

  function distance2(p) {
    var dx = p.x - point.x,
      dy = p.y - point.y
    return dx * dx + dy * dy
  }
}
