import { useState, useEffect, useRef, useContext } from 'react'
import Point from '../components/Point'
import Legend from '../components/Legend'
import { bellCurve } from '../utils/chart.js'
import { ChartDataContext } from '../pages/CardSection'

const Chart = () => {
  const { chartData } = useContext(ChartDataContext)
  const [mounted, setMounted] = useState(false)
  const pathElement = useRef()

  useEffect(() => {
    pathElement.current.setAttribute('d', bellCurve(0, 400, 125))
    setMounted(true)
  }, [])

  return (
    <div className="shell">
      {/* Chart */}
      <div
        id={`c-${chartData.id}`}
        className="invisible px-3 mt-3 mb-1 bg-top bg-repeat-y opacity-0 chart-bg"
      >
        <svg
          id={`box-${chartData.id}`}
          className="h-auto overflow-visible"
          viewBox="0 0 400 125"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id={`path-${chartData.id}`}
            className="text-gray-400 stroke-current"
            fill="none"
            ref={pathElement}
          />
          {mounted &&
            chartData.points?.map((pointData) => (
              <Point
                data={pointData}
                path={pathElement.current}
                key={pointData.id}
              />
            ))}
        </svg>
      </div>

      {/* X-Axis */}
      <div className="px-2">
        <div className="flex flex-row justify-around pt-1 text-xs uppercase border-t border-gray-500 border-opacity-70">
          <p>Figuring things out</p>
          <p>Getting things done</p>
        </div>
      </div>

      {/* Legend */}
      {mounted && <Legend />}
    </div>
  )
}

export default Chart
