import { Switch, Route } from 'react-router-dom'
import Home from './pages/Home'
import CardSection from './pages/CardSection'
import Authorization from './pages/Authorization'

function App() {
  return (
    <div>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/card-section">
          <CardSection />
        </Route>
        <Route path="/auth">
          <Authorization />
        </Route>
      </Switch>
    </div>
  )
}

export default App
