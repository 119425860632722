export const getCapabilities = () => {
  window.TrelloPowerUp.initialize(
    {
      'card-back-section': (t, opts) => {
        return t
          .getRestApi()
          .isAuthorized()
          .then((isAuthorized) => {
            return t
              .get('card', 'shared', 'showHillChart')
              .then((showHillChart) => {
                if (isAuthorized && showHillChart) {
                  return {
                    title: 'Hill Chart',
                    icon: 'https://cdn.glitch.com/1b42d7fe-bda8-4af8-a6c8-eff0cea9e08a%2Frocket-ship.png?1494946700421',
                    content: {
                      type: 'iframe',
                      url: t.signUrl('./card-section'),
                      height: 100,
                    },
                    action: {
                      text: 'Options',
                      callback: (t, opts) => {
                        console.log('clicked options')
                        return t
                          .popup({
                            title: 'Chart Options',
                            items: [
                              {
                                text: 'Disable chart',
                                callback: (t) => {
                                  t.popup({
                                    type: 'confirm',
                                    title: 'Disable chart?',
                                    message: 'Are you sure?',
                                    confirmText: 'Disable',
                                    confirmStyle: 'danger',
                                    onConfirm: (t) => {
                                      return t
                                        .set(
                                          'card',
                                          'shared',
                                          'showHillChart',
                                          false
                                        )
                                        .then(() => t.closePopup())
                                        .catch((err) => console.error(err))
                                    },
                                  }).catch((err) => console.error(err))
                                },
                              },
                              {
                                text: 'Clear Auth Token',
                                callback: (t) => {
                                  return t
                                    .getRestApi()
                                    .clearToken()
                                    .then(() => t.closePopup())
                                },
                              },
                            ],
                          })
                          .catch((err) => console.error(err))
                      },
                    },
                  }
                } else {
                  console.log('hill chart not enabled!')
                }
              })
              .catch((err) => console.error(err))
          })
      },
      'card-buttons': (t, opts) => {
        return t
          .getRestApi()
          .isAuthorized()
          .then((isAuthorized) => {
            if (isAuthorized) {
              console.log('authed')
              return t
                .get('card', 'shared', 'showHillChart')
                .then((showHillChart) => {
                  if (showHillChart) {
                    // TODO: Refresh chart button
                    return [
                      {
                        text: 'Refresh Hill Chart',
                        callback: (t) => {
                          console.log('refreshing...')
                        },
                      },
                    ]
                  } else {
                    return [
                      {
                        text: 'Track on Hill Chart',
                        callback: (t) => {
                          console.log('enabling hill chart...')
                          return t.set('card', 'shared', 'showHillChart', true)
                        },
                      },
                    ]
                  }
                })
            } else {
              console.log('no auth button')
              return [
                {
                  text: 'Track on Hill Chart',
                  callback: (t) => {
                    return t.popup({
                      title: 'Authorize Hill Charts',
                      url: t.signUrl('./auth'),
                    })
                  },
                },
              ]
            }
          })
          .catch((err) => console.error('card button error:', err))
      },
    },
    {
      appKey: '7e89269b619fe402fc90b5167263feae',
      appName: 'Hill Charts',
    }
  )
}
